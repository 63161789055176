<template>
    <tr class="clickable-row">
        <td><a href="">{{transaction.id}}</a></td>
        <td><a href=""><span class="label label-default">{{StringsHelper.getChannel(transaction.channel)}}</span></a></td>
        <td><a href="">{{Utils.getDateStringFromISO(transaction.purchase_date)}}</a></td>
        <td><a href=""><span v-if="transaction.creator">{{StringsHelper.getOfferta(transaction)}}</span></a></td>
        <td><a href=""> <span v-if="transaction.creator">{{transaction.creator.display_name}}</span> </a></td>
        <td><a href=""> {{StringsHelper.getPaymentMode(transaction.payment_mode)}}</a></td>
        <td><a href="" class="text-right bold">€{{transaction.price_final}}</a></td>
        <td><a href="" class="text-right">€{{getFee()}}</a></td>
        <td><a href="" class="text-right">€{{transaction.price_net}}</a></td>
    </tr>
</template>

<script>
    
    import baseMixin from '@/common/baseMixin'

    export default {
        name: "TransactionRow",
        mixins: [baseMixin],
        props: {
            transaction: {
                type: Object,
                required: true
            }
        },
        methods:{
            getFee(){
                let n = this.transaction.price_final - this.transaction.price_net;
                return n.toFixed(2)
            }
        }
    }
</script>
