<template>
    <div class="col-xs-10 text-right">
        <div class="btn-toolbar pull-right">
            <div class="btn-group">
                <button id="time-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-calendar" aria-hidden="true"></span>
                    <span v-if="date">{{StringsHelper.getTransactionDateSimple(date)}} </span>
                    <span v-else>Tutte le prenotazioni </span>
                    <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="time-dropdown">
                    <li v-for = "time in dateOptions" :key = "time.value"><input type="hidden" name = "creatorsOptions" :value="time.value" /><a @click.prevent="date=time.value" href = "">{{time.name}}</a></li>
                    <li><input type="hidden" name = "creatorsOptions" :value="null" /><a @click.prevent="date=null" href = "">Tutte le transazioni</a></li>
                </ul>
            </div>
            <div class="btn-group">
                <button id="user-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-user" aria-hidden="true"></span> <span id="user-dropdown-btn-text">{{StringsHelper.getCreatorSimple(filter.creator)}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="user-dropdown" >
                    <li><input type="hidden" :value="null" /><a @click.prevent="filterCreatorChanged()" href = "">{{StringsHelper.getCreatorSimple()}}</a></li>
                    <li v-for = "creator in creatorsOptions" :key = "creator.value"><input type="hidden" name = "creatorsOptions" :value="creator.value" /><a @click.prevent="filterCreatorChanged(creator.value)" href = "">{{creator.name}}</a></li>
                </ul>
            </div>
            <div class="btn-group">
                <button id="payment-mode-dropdown-btn" type="button" class="btn btn-default btn-sm dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="glyphicon glyphicon-euro" aria-hidden="true"></span> <span id="payment-mode-dropdown-btn-text">{{StringsHelper.getPaymentModeSimple(filter.payment_mode)}}</span> <span class="caret"></span>
                </button>
                <ul class="dropdown-menu pull-right" id="payment-mode-dropdown">
                    <li><input type="hidden" :value="null" /><a @click.prevent="filterPaymentChanged()" href = "">{{StringsHelper.getPaymentModeSimple()}}</a></li>
                    <li v-for = "payment in paymentOptions" :key = "payment.value"><input type="hidden" name = "paymentOptions" :value="payment.value" /><a @click.prevent="filterPaymentChanged(payment.value)" href = "">{{payment.name}}</a></li>
                </ul>
            </div>
            <a class="btn btn-default btn-sm hide-print" href="javascript:;" onclick="window.print();">
                <span class="glyphicon glyphicon-print" aria-hidden="true"></span> Stampa
            </a>
        </div>
	</div>
</template>

<script>
import baseMixin from '@/common/baseMixin'

	export default {
		
		name: "TransactionFilters",
		mixins: [baseMixin],
		props: {
			defaultDateFilter: {
                type: String,
                default: "today"
            }
		},
		mounted () {
			this.creatorsOptions = this.Keys.CREATORS_OPTIONS();
			this.paymentOptions = this.Keys.PAYMENT_MODE_OPTIONS();
			this.dateOptions = this.Keys.TRANSACTIONS_DATE_OPTIONS();

            let dateFilter = this.Utils.getStartEndDateFromString(this.defaultDateFilter);
            this.filter.start_date = dateFilter.start_date;
            this.filter.end_date = dateFilter.end_date;
		},

		data: () => {
			return {
                loading: false,
                date: "today",
                creatorsOptions: [],
                paymentOptions: [],
				dateOptions: [],
                filter: {
                    start_date: null,
                    end_date: null,
                    creator: null,
                    payment_mode: null,
                }
            }
		},
		methods: {
			filterPaymentChanged(paymentMode=null) {
                this.filter.payment_mode = paymentMode;
				this.$emit('filter-changed', this.filter);
			},
            filterCreatorChanged(creator=null) {
                this.filter.creator = creator;
				this.$emit('filter-changed', this.filter);
			},
            filterDateChanged(start_date = null, end_date = null){
                this.filter.start_date = start_date;
                this.filter.end_date = end_date;
				this.$emit('filter-changed', this.filter);
            }
		},
        watch: {
            date: function(time) {
                let dateFilter = this.Utils.getStartEndDateFromString(time);
                this.filterDateChanged(dateFilter.start_date,dateFilter.end_date);
            }
        }
	}
</script>
