<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
			<div class="row">
				<div class="col-xs-2">
					<h1 class="h2 margin-bottom-20">Transazioni</h1>
				</div>
				<TransactionFilters @filter-changed="filterChanged" :default-date-filter = "dateFilter"/>
			</div>
			<TransactionTable :transactions = "transactions"/>
          <div class="text-center">
            <Pagination :page-count="pagesElements" :click-handler="paginateCallback"/>
          </div>
		</div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import TransactionTable from '../components/Transaction/TransactionTable'
    import TransactionFilters from '../components/Transaction/TransactionFilters'
    import {getTransactions} from "../api"
    import baseMixin from '../common/baseMixin'
    import Pagination from "../components/common/Pagination"



    export default {
      title: "Transazioni",
      name: "Transactions",
      components: {ContainerWithMenu,TransactionTable,TransactionFilters,Pagination}, mixins: [baseMixin],

      data: function() {
            return {
                loading: false,
                transactions: {},
				dateFilter: "today",
                filterEnabled: {}
            }
        },
        created() {
            this.filterEnabled = this.Utils.getStartEndDateFromString(this.dateFilter);
            this.getTransactions(1);
        },
        methods: {
            paginateCallback(pageNum) {
              this.getTransactions(pageNum);
            },
            async getTransactions(page = 1,filter=this.filterEnabled) {
                this.loading = true;
                try {
                    let transactionsResponse = await getTransactions(page,filter);
                    this.transactions = transactionsResponse;
                    this.countElements = transactionsResponse.count;

                  this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            filterChanged(filter){
                this.filterEnabled = filter;
                this.getTransactions(1,filter)
            }
        }
    };
</script>
